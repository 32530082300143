import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { BookmarksList } from 'src/app/api/models/bookmarks-list';
import { Dashboard } from 'src/app/api/models/dashboard';
import { VideoTeaser } from 'src/app/api/models/video-teaser';
import { DashboardActions } from 'src/app/dashboard/dashboard.actions';

@Component({
  selector: 'app-curated-lists',
  templateUrl: './curated-lists.component.html',
  styleUrls: ['./curated-lists.component.sass'],
})
export class CuratedListsComponent implements OnInit {
  public dashboard$: Observable<Dashboard>;
  public ghostTeasersCount = Array(7);
  public ghostTeaserRows = Array(7);

  page = 'Yoga-Home';
  private destroy$: Subject<boolean> = new Subject<boolean>();

  bookmarksLists: Array<BookmarksList>;
  favorites: Array<VideoTeaser>;
  favoritesVideoCount: number;
  public bannerImg: string;
  public tabletBannerImg: string;
  public mobileBannerImg: string;
  constructor(
    private store: Store,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.bannerImg =
      locale === 'de'
        ? 'https://cdn.yogaeasy.de/production/uploads/components/dashboard/banners/dashboard_program_desktop_de.png'
        : 'https://cdn.yogaeasy.de/production/uploads/components/dashboard/banners/dashboard_program_desktop_en.png';

    // Set mobile banner image based on locale
    this.tabletBannerImg =
      locale === 'de'
        ? 'https://cdn.yogaeasy.de/production/uploads/components/dashboard/banners/dashboard_program_tablet_de.png'
        : 'https://cdn.yogaeasy.de/production/uploads/components/dashboard/banners/dashboard_program_tablet_en.png';

    // Set mobile banner image based on locale
    this.mobileBannerImg =
      locale === 'de'
        ? 'https://cdn.yogaeasy.de/production/uploads/components/dashboard/banners/dashboard_program_mobile_de.png'
        : 'https://cdn.yogaeasy.de/production/uploads/components/dashboard/banners/dashboard_program_mobile_en.png';
  }

  ngOnInit(): void {
    this.dashboard$ = this.store.select(state => state.dashboard?.dashboard);
    this.store.dispatch(new DashboardActions.FetchDashboard());
    this.store
      .select(state => state.bookmarks.bookmarksLists)
      .pipe(takeUntil(this.destroy$))
      .subscribe(bookmarksLists => {
        if (bookmarksLists?.length) {
          // FIXME: would be nice to only get the number here, not all teasers
          this.store.dispatch(
            new DashboardActions.FetchVideoTeasers('favorites'),
          );
        } else {
          this.store.dispatch(
            new DashboardActions.FetchVideoTeasers('favorites', 7),
          );
        }
        this.bookmarksLists = bookmarksLists;
      });

    this.store
      .select(state => state.dashboard.favoritesVideoCount)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        favoritesVideoCount => (this.favoritesVideoCount = favoritesVideoCount),
      );
    this.store
      .select(state => state.dashboard.favoritesVideoTeasers)
      .pipe(takeUntil(this.destroy$))
      .subscribe(videoTeasers => (this.favorites = videoTeasers));
  }
}
