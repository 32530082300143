<div
  class="pt-4 pb-4 is-width-teaser is-height-100 has-text-kokoda has-background-white has-border-1px-quill-grey has-border-radius-5 is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-teaser"
  [style]="customCss">
  <ng-container *ngIf="!hideText">
    @if (type !== 'favorite') {
    <svg-search class="icon is-medium mb-5"></svg-search>
    }
    @switch (type) {
    @case ('article') {
    <div class="mb-5 more-teaser-text " i18n="More Teaser: Article@@moreTeaserArticle">Noch nicht das richtige gefunden?</div>
    }
    @case ('bookmarks') {
    <div class="mb-5 more-teaser-text" i18n="More Teaser: Bookmarks@@moreTeaserBookmarks">Noch nicht das richtige gefunden?</div>
    }
    @case ('podcast') {
    <div class="mb-5 more-teaser-text" i18n="More Teaser: Podcast@@moreTeaserPodcasts">Noch nicht das richtige gefunden?</div>
    }
    @case ('program') {
    <div class="mb-5 more-teaser-text" i18n="More Teaser: Program@@moreTeaserProgram">Noch nicht das richtige gefunden?</div>
    }
    @case ('teacher') {
    <div class="mb-5 more-teaser-text" i18n="More Teaser: Teacher@@moreTeaserTeacher">Noch nicht das richtige gefunden?</div>
    }
    @case ('video') {
    <div class="mb-5 more-teaser-text" i18n="More Teaser: Video@@moreTeaserVideo">Noch nicht das richtige gefunden?</div>
    }
    @case ('favorite') {
    <div class="mb-5 more-teaser-text" i18n="More Teaser: Favorite@@moreTeaserFavorite">Noch nicht das richtige gefunden?</div>
    }
    }
  </ng-container>
  <a [href]="href" class="button is-primary is-outlined is-rounded has-text-weight-semibold teaser-button">
    @switch (type) {
    @case ('article') {
    <div i18n="More Teaser: Article">Alle Artikel anzeigen</div>
    }
    @case ('bookmarks') {
    <div i18n="More Teaser: Article">Alle Listen anzeigen</div>
    }
    @case ('podcast') {
    <div i18n="More Teaser: Podcast">Alle Episoden anzeigen</div>
    }
    @case ('program') {
    <div i18n="More Teaser: Program">Alle Programme anzeigen</div>
    }
    @case ('teacher') {
    <div i18n="More Teaser: Teacher">Alle Lehrer:innen anzeigen</div>
    }
    @case ('video') {
    <div i18n="More Teaser: Video">Alle Videos anzeigen</div>
    }
    @case ('favorite') {
    <div i18n="More Teaser: Favorite">Alle Favoritenlisten anzeigen</div>
    }
    }
    @if (count) {
    &nbsp;({{ count }})
    }
  </a>
</div>