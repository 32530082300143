@if (dashboard$ | async; as dashboard) {
@for (slider of dashboard.sliders; track slider.headline) {
@if($index === 3) {
<app-live-section />
}
@if ($index === 1) {
@if (bookmarksLists?.length || favorites?.length) {
<app-teaser-slider class="favorite-slider" i18n-headline i18n-subheadline headline="Favoritenlisten" [showCount]="true"
  [showAllLink]="'/meine-videos/meine-listen'" [count]="bookmarksLists?.length"
  subheadline="Deine liebsten Videos und selbst erstellte Listen" headlineLink="/meine-videos/meine-listen">
  <app-bookmarks-list-favorite-teaser [itemsCount]="favoritesVideoCount" class="teaser"></app-bookmarks-list-favorite-teaser>
  <app-add-bookmarks-list-teaser class="teaser is-not-for-mobile"></app-add-bookmarks-list-teaser>
  <app-bookmarks-list-teaser *ngFor="let list of bookmarksLists" [teaser]="list" class="teaser"></app-bookmarks-list-teaser>
  <app-add-bookmarks-list-teaser class="teaser is-for-mobile"></app-add-bookmarks-list-teaser>
  @if(bookmarksLists?.length >= 10) {
  <app-more-teaser type="favorite" href="/meine-videos/meine-listen" />
  }
</app-teaser-slider>
}
@if (!bookmarksLists?.length && !favorites?.length) {
<div class="mb-6">
  <app-empty-favorite-message></app-empty-favorite-message>
</div>
}
<div class="cta-banner-container">

  <a [href]="'/meine-programme'">
    <img src="{{bannerImg}}" class="banner-container-img banner-container" />
    <img src="{{tabletBannerImg}}" class="banner-container-img banner-container-tablet" />
    <img src="{{mobileBannerImg}}" class="banner-container-img banner-container-mobile" />
    <a [href]="'/meine-programme'"
      class="cta-btn button is-primary is-rounded is-medium has-text-weight-semibold is-position-relative  is-bottom-86px-mobile is-bottom-85px-tablet is-bottom-126px-mobile is-bottom-74px-desktop is-centered-horizontally-mobile is-left-520px-tablet is-left-380px-tablet is-left-550px-tablet is-left-715px-desktop px-6"
      i18n>Meine Programme</a>
  </a>
</div>
}
@if(slider.headline !== 'Live online classes'){
<app-teaser-slider [headline]="slider.headline" [subheadline]="slider.subheadline">
  @switch(slider.type) {
  @case('article') {
  <app-article-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('podcast') {
  <app-podcast-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('program') {
  <app-program-new-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('teacher') {
  <app-teacher-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  @case('video') {
  <app-video-teaser *ngFor="let teaser of slider.teasers" [teaser]="teaser" size="small" />
  }
  }
  <app-more-teaser [type]="slider.type" [href]="slider.link" />
</app-teaser-slider>
}
}
} @else {
<app-ye-loading-spinner />
}