<div class="my-videos-page">
  @if (bookmarksLists?.length || favorites?.length) {
  <app-teaser-slider i18n-headline i18n-subheadline headline="Favoritenlisten" [showCount]="true"
    [showAllLink]="'/meine-videos/meine-listen'" [count]="bookmarksLists?.length"
    subheadline="Deine liebsten Videos und selbst erstellte Listen" headlineLink="/meine-videos/meine-listen">
    <app-bookmarks-list-favorite-teaser [itemsCount]="favoritesVideoCount" class="teaser"></app-bookmarks-list-favorite-teaser>
    <app-add-bookmarks-list-teaser class="teaser"></app-add-bookmarks-list-teaser>
    <app-bookmarks-list-teaser *ngFor="let list of bookmarksLists" [teaser]="list" class="teaser"></app-bookmarks-list-teaser>
    <app-more-teaser *ngIf="bookmarksLists?.length >= 10" type="bookmarks" href="/meine-videos/meine-listen" [hideText]="true"
      [customCss]="'width: 176px; border: none'" />
  </app-teaser-slider>
  }
  <app-teaser-slider i18n-headline headline="Zuletzt gesehen" headlineLink="/homepage/videos/zuletzt-gesehen">
    <ng-container *ngIf="lastViewed?.length">
      <app-video-teaser *ngFor="let teaser of lastViewed" [teaser]="teaser" size="small" [breadcrumb]="[page, 'Zuletzt-gesehen']"
        class="teaser"></app-video-teaser>
      <app-more-teaser type="video" href="/homepage/videos/zuletzt-gesehen" />
    </ng-container>
    <app-teaser-slider-empty-message slot="empty" [displayHeadline]="false">
      <a class="light-text" routerLink="/video-kategorien" i18n> Starte jetzt mit deiner ersten Yoga-Session </a>
    </app-teaser-slider-empty-message>
  </app-teaser-slider>

  @if (!bookmarksLists?.length && !favorites?.length) {
  <div class="mt-6">
    <app-empty-favorite-message></app-empty-favorite-message>
  </div>
  }
  <app-teaser-slider *ngIf="ratedGood?.length" i18n-headline headline="Von mir gut bewertet" headlineLink="/homepage/videos/gut-bewertet">
    <app-video-teaser *ngFor="let teaser of ratedGood" [teaser]="teaser" size="small" [breadcrumb]="[page, 'Gut-bewertet']"
      class="teaser"></app-video-teaser>
    <app-more-teaser type="video" href="/homepage/videos/gut-bewertet" />
  </app-teaser-slider>
</div>